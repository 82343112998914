var $ = jQuery.noConflict();

// J3W app ---------------------------------------------------------------------

(function ($) {

    'use strict';

    function J3WTheme() {

        var self = this;
        
        self.$window = $(window);
        self.$document = $(document);
        self.$body = $("body");
        self.$header = $("#header");
        self.$hamburger = $(".hamburger");
        self.$mainMenu = $("#main-menu");
        self.$mainNav = $("#main-nav");
        self.$mobileMenu = $("#mobile-menu");

        self.$videoHandler = $("#video-handler");
        self.$handlerChooseTime = $(".handler-video-time");
        self.state = null;
        
        self.init();

    }

    J3WTheme.prototype = {

        init: function () {

            var self = this;

            self.onBind();

            self.playVideo();

        },

        onBind: function () {

            var self = this;

            self.$handlerChooseTime.on("click", function () {
                self.changeVideoTime( $(this) );
            });

            self.$window.on('scroll', function () {
                self.bodyScrollClass(self);
            });

            self.$hamburger.on("mousedown", function () {
                self.mobileMenu();
            });

        },

        playVideo: function() {

            var self = this;

            var url, thumb, source;

            // Pokud na strance nemame kontejner pro to video
            // Stopnem to
            if( !self.$videoHandler.length > 0 ){
                return false;
            }

            url = self.$videoHandler.data("url");
            thumb = self.$videoHandler.data("thumb");

            // 1) Naplni z dat vlozenych primo k tomu kontejneru
            // 2) Naplni se z dat z te dlazdice
            // 3) Kdyz klepneme na zpet v browseru
            source = [{
                type: "application/x-mpegURL",
                src: url
            }]

            // Iniciujeme video player (poprve pri reloadu stranky)
            // Musime jeste zajistit, aby se zmenila URL
            // Kdyby to nekdo reloadnul a vybral si video z toho praveho sloupce
            self.vgsPlayer = videojs("video-handler", {
                autoplay: false,
                poster: thumb,
                sources: source
            });

            //self.vgsPlayer.play();

        },

        changeVideoTime: function( $this ) {

            var self = this;

            self.vgsPlayer.currentTime( $this.data("time") );
            self.vgsPlayer.play();
        },

        mobileMenu: function(){

            var self = this, t;

            clearTimeout( t );

            // Tohle se asi nebude pouzivat
            self.$body.toggleClass("sideoutarea-is-open");

            // Pro vsechny hamburgery
            var $hamburger = $(".hamburger");

            if( self.$mobileMenu.hasClass("open") ){

                self.$mobileMenu.removeClass("open");

                t = setTimeout( function(){
                    $hamburger.removeClass("is-active");
                }, 200 );

            }
            else {

                self.$mobileMenu.addClass("open");

                t = setTimeout( function(){
                    $hamburger.addClass("is-active");
                }, 200 );

            }

        },

        mobileMenuClose: function() {

            var self = this;

            self.$mobileMenu.removeClass("open");
            // Pro vsechny hamburgery
            $(".hamburger").removeClass("is-active");
            // Tohle se asi nebude pouzivat
            self.$body.removeClass("sideoutarea-is-open");

        },

        bodyScrollClass: function ( self ) {

            if (self.$document.scrollTop() > self.$header.outerHeight()) {
                self.$body.addClass('scroll');
            } else {
                self.$body.removeClass('scroll');
            }

            // potrebujeme vedet, jestli se scrolluje dolu nebo nahoru
            /*if ( ( document.body.getBoundingClientRect() ).top > self.scrollPos ) {
                self.$body.addClass('scroll-up');
            } else {
                self.$body.removeClass('scroll-up');
            }
            
            self.scrollPos = ( document.body.getBoundingClientRect() ).top;
            
            if( self.$document.scrollTop() < self.$header.outerHeight() + 100 ) {
                self.$body.addClass('hide-menu');
            } else {
                self.$body.removeClass('hide-menu');
            }*/

        }
    };

    // Add core script to $.j3wTheme so it can be extended
    $.j3wTheme = J3WTheme.prototype;

    $(document).ready(function () {
        // Initialize script
        new J3WTheme();
    });

})(jQuery);